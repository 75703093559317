// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfAqM6rCySViaeNTLWd9jdN62N-t-YtEk",
  authDomain: "stdc1-dc446.firebaseapp.com",
  databaseURL: "https://stdc1-dc446-default-rtdb.firebaseio.com",
  projectId: "stdc1-dc446",
  storageBucket: "stdc1-dc446.appspot.com",
  messagingSenderId: "53385764498",
  appId: "1:53385764498:web:36a4546407ab5c301dd04b",
  measurementId: "G-XJX3TVBW5N"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };